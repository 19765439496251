import { useEffect, useState } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  // RefreshIcon,
  // useModal,
  UserMenu as UIKitUserMenu,
  // UserMenuItem,
  UserMenuVariant,
  Box,
  Text,
  LinkExternal,
  Button,
  Skeleton,
  ArrowForwardIcon,
} from 'packages/uikit'
// import Trans from 'components/Trans'
import useAuth from 'hooks/useAuth'
// import { useRouter } from 'next/router'
// import { useProfile } from 'state/profile/hooks'
import { usePendingTransactions } from 'state/transactions/hooks'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
// import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getBscScanLink } from 'utils'
import { FlexGap } from 'components/Layout/Flex'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import { formatBigNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { FetchStatus } from 'config/constants/types'
import tokens from 'config/constants/tokens'
import { ETHER } from '@hybrid-dex/sdk'
import { CurrencyLogo } from 'components/Logo'
import { WalletView } from './WalletModal'
// import ProfileUserMenuItem from './ProfileUserMenuItem'
// import WalletUserMenuItem from './WalletUserMenuItem'
import { CopyButton } from '../../CopyButton'
import UserMenuWrapper from './UserMenuWrapper'
import WalletTransactions from './WalletTransactions'

const UserMenu = () => {
  // const router = useRouter()
  const { t } = useTranslation()
  const { account, error } = useWeb3React()
  const { logout } = useAuth()
  const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
  // const { isInitialized, isLoading, profile } = useProfile()
  // const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  // const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  // const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
  // const hasProfile = isInitialized && !!profile
  // const avatarSrc = profile?.nft?.image?.thumbnail
  const [userMenuText, setUserMenuText] = useState<string>('')
  const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
  const isWrongNetwork: boolean = error && error instanceof UnsupportedChainIdError
  const accountEllipsis = account ? `${account.substring(0, 6)}...${account.substring(account.length - 6)}` : null
  const [view, setView] = useState(WalletView.WALLET_INFO)

  const handleOpenTransaction = (e) => {
    e?.stopPropagation()
    setView(WalletView.TRANSACTIONS)
  }

  const handleOpenInfo = (e) => {
    e?.stopPropagation()
    setView(WalletView.WALLET_INFO)
  }

  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance, fetchStatus: cakeFetchStatus } = useTokenBalance(tokens.cake.address)

  useEffect(() => {
    if (hasPendingTransactions) {
      setUserMenuText(t('%num% Pending', { num: pendingNumber }))
      setUserMenuVariable('pending')
    } else {
      setUserMenuText('')
      setUserMenuVariable('default')
    }
  }, [hasPendingTransactions, pendingNumber, t])

  // const onClickWalletMenu = (): void => {
  //   if (isWrongNetwork) {
  //     onPresentWrongNetworkModal()
  //   } else {
  //     onPresentWalletModal()
  //   }
  // }

  const [gradient, setGradient] = useState('')

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const getRandomGradient = () => {
    const color1 = getRandomColor()
    const color2 = getRandomColor()
    const color3 = getRandomColor()
    const color4 = getRandomColor()
    return `linear-gradient(45deg, ${color1}, ${color2}, ${color3}, ${color4})`
  }

  useEffect(() => {
    setGradient(getRandomGradient())
  }, [])

  const UserMenuItems = () => {
    return (
      <>
        <Box background="#303030" padding="5px 15px" borderRadius="10px" boxShadow="4px 4px 17px 0px #0000007D">
          {view === WalletView.WALLET_INFO ? (
            <>
              <Flex flexDirection="column" borderBottom="1px solid rgba(255,255,255,.5)">
                <Flex alignItems="center" justifyContent="space-between" style={{ gap: '8px' }} padding="10px 8px">
                  <Flex alignItems="center" style={{ gap: '8px' }}>
                    <Box minWidth="30px" height="30px" borderRadius="50%" background={gradient} />
                    <Text fontSize={['16px']}>{accountEllipsis}</Text>
                  </Flex>

                  <FlexGap alignItems="center" gap="5px">
                    <CopyButton width="12px" text={account} tooltipMessage={t('Copied')} tooltipTop={-40} />
                    <LinkExternal margin={false} iconWidth="12px" href={getBscScanLink(account, 'address')} />
                  </FlexGap>
                </Flex>
                <Box mb="16px">
                  {fetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text
                      fontWeight="700"
                      fontSize={['18px', , , , '25px']}
                      textTransform="uppercase"
                      textAlign="center"
                    >
                      {/* {getFullDisplayBalance(cakeBalance, 18, 3)} {tokens.cake.symbol} */}
                      {formatBigNumber(balance, 3)} {ETHER.symbol}
                    </Text>
                  )}
                </Box>
              </Flex>
              <FlexGap flexDirection="column" borderBottom="1px solid rgba(255,255,255,.5)" py="10px" gap="8px">
                <Text small>Token</Text>

                <FlexGap width="100%" justifyContent="space-between" alignItems="center" mb="8px">
                  <FlexGap gap="5px" alignItems="center">
                    <CurrencyLogo currency={ETHER} size="28px" style={{ marginRight: 0 }} />
                    <Flex flexDirection="column">
                      <Text lineHeight="1" fontSize="16px">
                        {ETHER.symbol}
                      </Text>
                      <Text lineHeight="1" fontSize="10px">
                        {ETHER.name}
                      </Text>
                    </Flex>
                  </FlexGap>
                  {fetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text fontSize={['16px']}>{formatBigNumber(balance, 6)}</Text>
                  )}
                </FlexGap>
                <FlexGap width="100%" justifyContent="space-between" alignItems="center" mb="8px">
                  <FlexGap gap="5px" alignItems="center">
                    <img width={28} src="/images/tokens/logo.png" alt="" />
                    <Flex flexDirection="column">
                      <Text lineHeight="1" fontSize="16px">
                        {tokens.cake.symbol}
                      </Text>
                      <Text lineHeight="1" fontSize="10px">
                        {tokens.cake.name}
                      </Text>
                    </Flex>
                  </FlexGap>
                  {cakeFetchStatus !== FetchStatus.Fetched ? (
                    <Skeleton height="22px" width="60px" />
                  ) : (
                    <Text fontSize={['16px']}>{getFullDisplayBalance(cakeBalance, 18, 3)}</Text>
                  )}
                </FlexGap>
              </FlexGap>
              <Box py="16px">
                <Button width="100%" height="40px" padding="0 8px" onClick={handleOpenTransaction}>
                  <Flex width="100%" alignItems="center" justifyContent="center" position="relative">
                    <Text color="black" fontSize="16px" bold>
                      Transactions
                    </Text>
                    <ArrowForwardIcon color="transparent" style={{ position: 'absolute', right: '0' }} />
                  </Flex>
                </Button>
              </Box>
            </>
          ) : (
            <WalletTransactions onBack={handleOpenInfo} />
          )}
          <Button variant="text" padding="0" height="auto" width="100%" mb="12px" onClick={logout}>
            <Text color="rgba(244, 59, 58, 1)" fontSize="16px" mr="5px">
              Disconnect
            </Text>
            <LogoutIcon color="transparent" width="14px" mr="6px" />
          </Button>
        </Box>
      </>
    )
  }

  if (account) {
    return (
      <UIKitUserMenu account={account} text={userMenuText} variant={userMenuVariable}>
        {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
      </UIKitUserMenu>
    )
  }

  if (isWrongNetwork) {
    return (
      <UserMenuWrapper text={t('Network')} variant="danger">
        {/* {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)} */}
      </UserMenuWrapper>
    )
  }

  return (
    <ConnectWalletButton height={45}>
      <Box display={['none', , , , 'block']}>
        <Text fontSize={['14px', , , , '18px']}>Connect Wallet</Text>
      </Box>
      <Box display={['block', , , , 'none']}>
        <Text fontSize={['14px', , , , '18px']}>Connect</Text>
      </Box>
    </ConnectWalletButton>
  )
}

export default UserMenu
