import { ChainId, Token } from '@hybrid-dex/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'
import addresses from './addresses.json'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = {
  eth: new Token(MAINNET, addresses[MAINNET]?.WETH, 18, 'BFT', 'BFT', 'https://'),
  cake: new Token(MAINNET, addresses[MAINNET].PlatformToken, 18, 'HYX', 'HybridEx', 'https://'),
  // usdt: new Token(
  //   MAINNET,
  //   '0x01445C31581c354b7338AC35693AB2001B50b9aE',
  //   6,
  //   'USDT',
  //   'Multichain Tether USD',
  //   'https://wagyuswap.app/',
  // ),
  weth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'WBFT', 'Wrapped BFT', 'https://'),
  icp: new Token(MAINNET, '0x51ccde9ca75d95bb55ece1775fcbff91324b18a6', 18, 'ICP', 'ICP', 'https://'),
  // bnb: new Token(MAINNET, '0x2b8e9cd44c9e09d936149549a8d207c918ecb5c4', 18, 'BNB', 'BNB', 'https:///'),
  // usdc: new Token(MAINNET, '0xb73603C5d87fA094B7314C74ACE2e64D165016fb', 6, 'USDC', 'USDC', 'https://'),
  busd: new Token(MAINNET, '0xf58ae68f5f295f4aa4a9d56065efaa5197f2baa9', 18, 'USDT', 'Tether USDT', 'https://'),
  usdc: new Token(MAINNET, '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4', 6, 'USDC', 'USD Coin', 'https://'),
  syrup: new Token(MAINNET, addresses[355110].PlatformToken, 18, '', '', 'https://'),
}

export const testnetTokens = {
  cake: new Token(TESTNET, addresses[763373].PlatformToken, 18, 'HYX', 'HybridEx', 'https://hybridex.xyz/'),
  weth: new Token(TESTNET, addresses[763373].WETH, 18, 'WBFT', 'Wrapped BFT', 'https://hybridex.xyz/'),
  icp: new Token(MAINNET, '0x51ccde9ca75d95bb55ece1775fcbff91324b18a6', 18, 'ICP', 'ICP', 'https://'),
  busd: new Token(TESTNET, '0x95512957E31cCE312ac2e6407e562228d3BC331b', 18, 'BUSD', 'BUSD', 'https://hybridex.xyz/'),
  usdc: new Token(
    TESTNET,
    '0x174c4c03dfea09682728a5959a253bf1f7c7766f',
    18,
    'USDC',
    'USD coin',
    'https://hybridex.xyz/',
  ),
}

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] }
    }, {} as typeof testnetTokens)
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens as any
}

export default unserializedTokens
